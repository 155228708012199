<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_internship')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.internshipType"
                            :error-messages="internshipTypeErrors"
                            :label="$t('table.internship_type')"
                            required outlined dense
                            @input="$v.editedData.internshipType.$touch()"
                            @blur="$v.editedData.internshipType.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.academicYear"
                            :error-messages="academicYearErrors"
                            :label="$t('academic_year')"
                            required outlined dense
                            @input="$v.editedData.academicYear.$touch()"
                            @blur="$v.editedData.academicYear.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.nameOfInstitute"
                            :label="$t('table.name_of_institute')"
                            required outlined dense
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.numberOfStudent"
                            type="number"
                           :label="$t('number_of_student')"
                            required outlined dense
                    ></v-text-field>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success", 'serviceID'],
        computed: {
            internshipTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.internshipType.$dirty) return errors;
                !this.$v.editedData.internshipType.required &&
                errors.push("Sector Type is required");
                return errors;
            },
            academicYearErrors() {
                const errors = [];
                if (!this.$v.editedData.academicYear.$dirty) return errors;
                !this.$v.editedData.academicYear.required &&
                errors.push("Sector Code is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 3000
                },
                snackbar: false,
                province: [],
                show: false,
                updated: false,
                editedData: {
                    internshipType: "",
                    academicYear: "",
                    nameOfInstitute: "",
                    numberOfStudent: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                internshipType: {required},
                academicYear: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                this.updated = false;
                let serviceData = await axios.get(`Academic/GetInternshipSettingByIDAsync/${this.serviceID}`);
                this.editedData = serviceData.data
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        internshipID: this.serviceID,
                        internshipType: this.editedData.internshipType,
                        academicYear: this.editedData.academicYear,
                        nameOfInstitute: this.editedData.nameOfInstitute,
                        numberOfStudent: parseInt(this.editedData.numberOfStudent),
                    };
                    axios.post("Academic/UpdateInternshipSettingAsync", param).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Internship updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.serviceName = "";
                this.editedData.serviceDescription = "";
            }
        }
    };
</script>

<style scoped>
</style>